<template>
  
<!-- Footer -->
<div class="container-fluid bg-dark footers">
  <div class="container">
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top text-light">
    <p class="col-md-4 mb-0 text-start">© 2024 Saqib <br>saqib.job.apps@gmail.com<br /></p>

    <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
      <svg class="bi bi-code-slash me-2" width="40" height="32" fill="white"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#code-slash"></use></svg>
    </a>

    <ul class="nav col-md-4 justify-content-end">
      <li><router-link class="nav-link px-2" :to="{name:'gigs'}">Portfolio</router-link></li>
    </ul>
    
  </footer>
  </div>
</div>

</template>

<script>
export default {
    name: 'FooterCo'
}
</script>

<style>
.footers ul li a {
  color: white;
}
</style>
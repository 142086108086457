<template>
  <header class="p-3 text-bg-dark">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
          <svg class="bi bi-code-slash me-2" width="40" height="32" role="img" aria-label="Bootstrap" fill="white"><use xlink:href="~bootstrap-icons/bootstrap-icons.svg#code-slash"></use></svg>
        </a>

        <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <li><router-link class="nav-link px-2 text-white" :to="{name:'gigs'}">Portfolio</router-link></li>
        </ul>

      </div>
    </div>
  </header>
</template>

<script>
export default {
    name: 'NavCo'
}
</script>

<style>

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>